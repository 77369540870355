import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<FeatureCard>`}</inlineCode>{` component takes the same props as the `}<inlineCode parentName="p">{`<ResourceCard>`}</inlineCode>{` component (except for `}<inlineCode parentName="p">{`aspectRatio`}</inlineCode>{`, this is fixed at `}<inlineCode parentName="p">{`1:2`}</inlineCode>{` for the card), this component should not be used within `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` components as the grid is built in to the component already.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <FeatureCard subTitle="With subtitle" title="Title" actionIcon="arrowRight" href="/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACBUlEQVQoz42Ty2tTQRSHpxbRv8BqxQdSF4J2Y8W9CwXX4iZt1TYqiEYLRVAxCoKg6Mb6ILgQRRDRFl2YTSlixUjFUhXb5L5y87pJqhUF64PU9PPcSQqJEnHxY5h75nzn/ObMVaZl00hePk8m61HIGHRf/4LaBYuD86i90H7qB4OjWYmZbD37HdUJi3ollnYt6pQ0SYm8rMvF8+cYuDKAl8sQjaXYfuErqgtUd1X70IU2nPipvzf1CPDZeIp6uVqxdx7bdgYIHT5CzvPIJA1yKZNI1KPtuAACVWigAlY+zF+X7C/zp5YeKNMchLX9v4ibLknHxjD9a3D4VHSZTDj03Z6hJTRHk29zAeav/n38JT8oFpZJgp/s2Ba27TAVjzM8MkrWTTA7naD/zozuUltdkKrdVKWr7oHlR0sCtLEtg7QM58H9e7S3reTq3RF2XJqT4uUKrNay6vE39dKHNFA6jItV02A6ZxMbm2Bj15B0/61yf9UBNQdrLf8D2BIqMWW4fChkuPakwGq5U3Wwek6G0XqsxOVHRTaHK89GO2sIlMor+sqMjb/n+fBjDt38iNot8U4ZmCT2Rj4zMelQzBp0nKl5hw2BkrjuJNyI3GL9mlUyjKdsOj3PlvAs0ZdpDXIdi4Rh0xH+H6B02Codvnr9lqHBh1hmnBdvUvoPyqdNDbKsimo7/A16l2Z/gnNwogAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "demo image",
          "title": "demo image",
          "src": "/static/ae93dc374fd305c3f957d48783714dbc/3cbba/large-image.png",
          "srcSet": ["/static/ae93dc374fd305c3f957d48783714dbc/7fc1e/large-image.png 288w", "/static/ae93dc374fd305c3f957d48783714dbc/a5df1/large-image.png 576w", "/static/ae93dc374fd305c3f957d48783714dbc/3cbba/large-image.png 1152w", "/static/ae93dc374fd305c3f957d48783714dbc/0b124/large-image.png 1728w", "/static/ae93dc374fd305c3f957d48783714dbc/91583/large-image.png 1886w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <FeatureCard title="Title" actionIcon="arrowRight" href="/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/2gAMAwEAAhADEAAAAaNb2wzDC5//xAAZEAADAAMAAAAAAAAAAAAAAAAAARECEDH/2gAIAQEAAQUChCC7klr/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIRIf/aAAgBAwEBPwFRwo//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAQIR/9oACAECAQE/AZs1/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQEAAT8hvNiJwzsiuf/aAAwDAQACAAMAAAAQk+//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QS6IP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EFHLV//EABoQAQACAwEAAAAAAAAAAAAAAAERUQAQYYH/2gAIAQEAAT8QEeZP0vQSCSsERKwQ6//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "smart work",
          "title": "smart work",
          "src": "/static/1d58a10e2ad13e4d817468913923c69c/2e753/smart-work.jpg",
          "srcSet": ["/static/1d58a10e2ad13e4d817468913923c69c/69549/smart-work.jpg 288w", "/static/1d58a10e2ad13e4d817468913923c69c/473e3/smart-work.jpg 576w", "/static/1d58a10e2ad13e4d817468913923c69c/2e753/smart-work.jpg 1152w", "/static/1d58a10e2ad13e4d817468913923c69c/74f4b/smart-work.jpg 1728w", "/static/1d58a10e2ad13e4d817468913923c69c/de5bb/smart-work.jpg 2304w", "/static/1d58a10e2ad13e4d817468913923c69c/45c10/smart-work.jpg 2832w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/FeatureCard/FeatureCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/FeatureCard",
        "path": "components/FeatureCard/FeatureCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/FeatureCard"
      }}>{`<FeatureCard
  subTitle="With subtitle"
  title="Title"
  actionIcon="arrowRight"
  href="/"
  disabled
  color="dark"
>
  ![demo image](/images/large-image.png)
</FeatureCard>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use large image as child, will display above the card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Large title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`Launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`ArrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`Download`}</inlineCode>{`, `}<inlineCode parentName="td">{`Disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`Email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      